var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap",style:({ minHeight: _vm.fullScreen ? '' : 0 })},[(_vm.tabs)?_c('div',{staticClass:"tabs fixed"},[_c('div',{staticClass:"tabs_wrap flex around",style:({
        borderTop: _vm.tabTopBorder ? '' : 0,
        borderBottom: _vm.tabBottomBorder ? '' : 0,
        '--active': _vm.active || '',
        '--inActive': _vm.inActive || '',
      })},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"tab_item flex flex-center relative",on:{"click":function($event){return _vm.tabChange(item)}}},[(item)?_c('div',{style:({
            color: _vm.tabVal
              ? _vm.tabNow === item[_vm.tabVal]
                ? 'var(--active)'
                : 'var(--inActive)'
              : _vm.tabKey
              ? _vm.tabNow === item[_vm.tabKey]
                ? 'var(--active)'
                : 'var(--inActive)'
              : _vm.tabNow === item
              ? 'var(--active)'
              : 'var(--inActive)',
          })},[_vm._v(" "+_vm._s(_vm.tabKey ? item[_vm.tabKey] : item)+" ")]):_vm._e(),(item)?_c('div',{staticClass:"absolute line",style:({
            backgroundColor: _vm.tabVal
              ? _vm.tabNow === item[_vm.tabVal]
                ? 'var(--active)'
                : ''
              : _vm.tabKey
              ? _vm.tabNow === item[_vm.tabKey]
                ? 'var(--active)'
                : ''
              : _vm.tabNow === item
              ? 'var(--active)'
              : '',
          })}):_vm._e()])}),0)]):_vm._e(),(_vm.tabs && _vm.tabFixed)?_c('div',{staticClass:"placeholder"}):_vm._e(),(_vm.list)?_c('div',{staticClass:"list"},[_c('van-list',{attrs:{"finished":_vm.finish,"finished-text":_vm.$t('Hint.notMore'),"loading-text":_vm.$t('Hint.loading')},on:{"load":_vm.nextPage},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.listData),function(item,index){return _c('div',{key:index},[(_vm.listData && _vm.listData.length && !_vm.customList)?_c('div',{staticClass:"padding0-12 top18"},[_c('div',{staticClass:"radius10 bg-fff"},[_vm._t("listItem",null,{"data":item}),_vm._t(_vm.tabNow,null,{"data":item})],2)]):_vm._e(),(_vm.listData && _vm.listData.length && _vm.customList)?_c('div',[_vm._t("listItem",null,{"data":item}),_vm._t(_vm.tabNow,null,{"data":item})],2):_vm._e()])}),(_vm.finish && _vm.listData.length)?_c('div',{staticClass:"nomore"}):_vm._e(),(_vm.finish && !_vm.listData.length)?_c('div',{staticClass:"nodata"},[_vm._t("null",[_c('van-image',{attrs:{"src":_vm.nullImg || _vm.verConfig.nullPng || _vm.noDataBack,"mode":"aspectFit"}})])],2):_vm._e()],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }